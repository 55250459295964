import axios from "axios";
import { useState, useEffect } from "react";
import { useAppErrorStore } from "./use-app-error-store";
import { useAppLoadingStore } from "./use-app-loading-store";
import { getStrapiUri } from "../lib/get-strapi-uri";
import { StrapiReturn } from "../types/strapi-return";

export function useStrapiFetch<T>(uri: string) {
	const [data, setData] = useState<StrapiReturn<T>>();
	const { setError } = useAppErrorStore();
	const { setLoading, unsetLoading } = useAppLoadingStore();

	useEffect(() => {
		async function fetch() {
			setLoading(uri);
			try {
				const strapiUri = getStrapiUri(uri);
				const res = await axios.get(strapiUri);
				const data = res.data.data as StrapiReturn<T>;
				setData(data);
			} catch (e: unknown) {
				setError();
			}
			unsetLoading(uri);
		}
		fetch();
	}, [setError, uri, setLoading, unsetLoading]);

	return { data };
}
