import { Navigation } from "./navigation/navigation";
import { LanguageToggle } from "./language-toggle";
import type { HeaderType, NavLinkType } from "../../types/main-page-types";
import { HeaderImage } from "./header-image";
import { useTranslation } from "react-i18next";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { useLocation } from "react-router-dom";

export function Header() {
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const { data } = useStrapiFetch<HeaderType>("/api/header?populate=image");
	let { data: navLinksData } = useStrapiFetch<NavLinkType[]>(
		"/api/nav-links?sort=order:asc&sort=createdAt:asc"
	);

	if (!data) {
		return null;
	}

	if (location.pathname === "/") {
		navLinksData = navLinksData?.filter((link) => link.attributes.showInHome);
	}

	const { image, href, textPL, textEN, textColor, bgColor } = data.attributes;
	const imageData = image.data.attributes;

	i18n.addResourceBundle(
		"pl",
		"translation",
		{ header: { button: textPL } },
		true,
		true
	);

	i18n.addResourceBundle(
		"en",
		"translation",
		{ header: { button: textEN } },
		true,
		true
	);

	return (
		<>
			<header className="w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9 z-50 relative">
				<div className="flex gap-10 items-center">
					<Navigation data={navLinksData} />

					<LanguageToggle />
				</div>
			</header>

			<HeaderImage {...imageData} />

			<div className="flex justify-center mt-2 md:mt-0">
				<a
					href={href}
					className="px-8 py-4 transition-opacity hover:opacity-90 text-2xl font-semibold text-white shadow-[0_6px_12px_0_#00000029] rounded-full block"
					style={{ backgroundColor: bgColor, color: textColor }}
				>
					{t("header.button")}
				</a>
			</div>
		</>
	);
}
