import { useTranslation } from "react-i18next";
import { Markdown } from "../common/markdown";
import type { NewType } from "../../types/main-page-types";
import type { StrapiReturn } from "../../types/strapi-return";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { NavLink } from "react-router-dom";

const MAX_BODY_LENGTH = 2000;

export function LatestNew({ data }: { data: StrapiReturn<NewType> }) {
	const { t } = useTranslation();

	const { url, alternativeText } = data.attributes.image.data.attributes;

	let body = t(`news.${data.id}.body`);
	let readMore = false;

	const withImages = !!body.match(/!\[.*\]\(.*\)/g);

	if (body.length > MAX_BODY_LENGTH) {
		body = body.slice(0, MAX_BODY_LENGTH).split("\n").slice(0, -1).join("\n");
		readMore = true;
	} else if (withImages) {
		readMore = true;
	}

	return (
		<div className="flex flex-col md:flex-row items-center md:items-start gap-4">
			<div className="md:order-2 flex flex-col gap-4 flex-1">
				<h3 className="font-semibold text-[#002969] text-lg sm:text-xl md:text-2xl">
					{t(`news.${data.id}.title`)}
				</h3>

				<Markdown className="[&_img]:hidden [&_p]:hidden [&_p:nth-child(1)]:block [&_p:nth-child(2)]:block [&_p:nth-child(3)]:block [&_p:nth-child(4)]:block [&_p:nth-child(5)]:block">
					{body}
				</Markdown>

				{readMore && (
					<div className="text-sm text-red-600">
						<NavLink to={`/aktualnosci/${data.id}`}>{t("news.read")}</NavLink>
					</div>
				)}
			</div>

			<div className="md:order-1 w-full max-w-sm">
				<div className="w-full h-0 pb-[80%] relative">
					<img
						src={getStrapiImageUrl(url)}
						alt={alternativeText}
						className="absolute top-0 left-0 w-full h-full object-contain rounded"
					/>
				</div>
			</div>
		</div>
	);
}
