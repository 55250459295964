import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getStrapiUri } from "../../lib/get-strapi-uri";
import { useState } from "react";

const positionEnum = {
	customerService: "customerService",
	chef: "chef",
	kitchenSupport: "kitchenSupport",
	dishwasher: "dishwasher",
};

export function CareerForm() {
	const { t } = useTranslation();

	const formSchema = z.object({
		fullname: z.string().trim().min(4, t("career.form.fullnameError")),
		email: z.string().trim().email(t("career.form.emailError")),
		position: z.nativeEnum(positionEnum),
		cv: z
			.any()
			.refine((file?: File) => !!file, t("career.form.cvError.required"))
			.refine(
				(file?: File) => file && file?.size <= 5 * 1024 * 1024,
				t("career.form.cvError.size", { size: "5mb" })
			)
			.refine(
				(file?: File) =>
					file?.type === "application/pdf" || file?.type === "image/*",
				t("career.form.cvError.type")
			),
		rodo: z.boolean().refine((value) => value, t("career.form.rodoError")),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			fullname: "",
			email: "",
			position: positionEnum["customerService"],
			cv: undefined,
			rodo: false,
		},
	});

	const isSubmitting = form.formState.isSubmitting;

	const { errors } = form.formState;

	const [submitError, setSubmitError] = useState("");
	const [onSuccess, setOnSuccess] = useState("");

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			setSubmitError("");
			setOnSuccess("");

			const formData = new FormData();

			const fileName = `cv_${values.fullname}_${Date.now()}`;
			formData.append(`files`, values.cv, fileName);

			const res = await axios.post(getStrapiUri("/api/upload"), formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			const cvId = res.data[0].id;
			formData.delete("files");
			formData.append("data", JSON.stringify({ ...values, cv: cvId }));

			await axios.post(getStrapiUri("/api/career-requests"), formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			setOnSuccess(t("career.form.onSuccess"));

			form.reset();
		} catch (error) {
			setSubmitError(t("career.form.submitError"));
		}
	}

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
				<Controller
					control={form.control}
					name="fullname"
					render={({ field }) => (
						<div className="flex flex-col">
							<label htmlFor="career-input-fullname" className="text-[#002969]">
								{t("career.form.fullname")}
							</label>
							<input
								{...field}
								id="career-input-fullname"
								autoComplete="name"
								placeholder=""
								disabled={isSubmitting}
								className="border border-gray-300 rounded p-1"
							/>
							{errors.fullname && (
								<p className="text-red-600 text-sm">
									{errors.fullname.message}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="email"
					render={({ field }) => (
						<div className="flex flex-col">
							<label htmlFor="career-input-email" className="text-[#002969]">
								{t("career.form.email")}
							</label>
							<input
								{...field}
								id="career-input-email"
								type="email"
								placeholder=""
								disabled={isSubmitting}
								className="border border-gray-300 rounded p-1"
							/>
							{errors.email && (
								<p className="text-red-600 text-sm">{errors.email.message}</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="position"
					render={({ field }) => (
						<div className="flex flex-col">
							<label htmlFor="career-input-position" className="text-[#002969]">
								{t("career.form.position.label")}
							</label>
							<select
								id="career-input-position"
								{...field}
								disabled={isSubmitting}
								className="border border-gray-300 rounded p-1"
							>
								{Object.values(positionEnum).map((key) => (
									<option key={key} value={key}>
										{t(`career.form.position.${key}`)}
									</option>
								))}
							</select>

							{errors.position && (
								<p className="text-red-600 text-sm">
									{errors.position.message}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="cv"
					render={({ field }) => (
						<div className="group">
							<label
								htmlFor="career-input-cv"
								className="text-red-600 font-bold underline cursor-pointer group-has-[:focus]:ring"
							>
								{!!form.getValues("cv") ? (
									<button
										onClick={() => field.onChange(undefined)}
										className="underline"
										disabled={isSubmitting}
									>
										{t("career.form.cvChange")}
									</button>
								) : (
									t("career.form.cv")
								)}
							</label>
							<input
								{...field}
								onChange={(e) => {
									const cv = e.target.files?.[0] || undefined;
									field.onChange(cv);
								}}
								id="career-input-cv"
								value=""
								type="file"
								accept=".pdf, image/*"
								className="sr-only"
								disabled={isSubmitting}
							/>
							{errors.cv && (
								<p className="text-red-600 text-sm">
									{String(errors.cv.message)}
								</p>
							)}
						</div>
					)}
				/>

				<Controller
					control={form.control}
					name="rodo"
					render={({ field }) => (
						<div>
							<div className="flex gap-8 items-center">
								<input
									{...field}
									id="career-input-rodo"
									type="checkbox"
									disabled={isSubmitting}
									value={field.value ? "true" : "false"}
									className=""
								/>
								<label
									htmlFor="career-input-rodo"
									className="text-[10px] text-[#002969] opacity-50"
								>
									{t("career.form.rodo")}
								</label>
							</div>
							{errors.rodo && (
								<p className="text-red-600 text-sm">{errors.rodo.message}</p>
							)}
						</div>
					)}
				/>

				{!!submitError && (
					<div className="text-center text-lg text-red-600">
						<p>{submitError}</p>
					</div>
				)}

				{!!onSuccess && (
					<div className="text-center text-lg text-[#002969]">
						<p>{onSuccess}</p>
					</div>
				)}

				<button
					type="submit"
					disabled={isSubmitting}
					className="px-8 py-4 bg-[#E03549] hover:opacity-90 transition-opacity font-semibold text-white shadow-[0_6px_12px_0_#00000029] rounded-full block mx-auto"
				>
					{isSubmitting ? t("career.form.onSubmit") : t("career.form.submit")}
				</button>
			</form>
		</FormProvider>
	);
}
