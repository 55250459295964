import axios from "axios";
import { useState, useEffect } from "react";
import { useAppErrorStore } from "./use-app-error-store";
import { useAppLoadingStore } from "./use-app-loading-store";

export function useFetch<T>(uri: string) {
	const [data, setData] = useState<T>();
	const { setError } = useAppErrorStore();
	const { setLoading, unsetLoading } = useAppLoadingStore();

	useEffect(() => {
		async function fetch() {
			setLoading(uri);
			try {
				const res = await axios.get(uri);
				const data = res.data as T;
				setData(data);
			} catch (e: unknown) {
				setError();
			}
			unsetLoading(uri);
		}
		fetch();
	}, [setError, uri, setLoading, unsetLoading]);

	return { data };
}
