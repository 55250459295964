import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export function NotFoundPage() {
	const { t } = useTranslation();

	return (
		<div className="py-12 flex flex-col md:flex-row justify-center items-center gap-6">
			<p className="font-semibold text-xl">{t("notFound.text")}</p>
			<NavLink to="/" className="px-10 py-2 rounded-full border">
				{t("notFound.button")}
			</NavLink>
		</div>
	);
}
