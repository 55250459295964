import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import type { NavLinkType } from "../../../types/main-page-types";

export function NavigationLinks({
	data,
	closeMenu,
}: {
	data?: {
		attributes: NavLinkType;
		id: number;
	}[];
	closeMenu?: () => void;
}) {
	const { t } = useTranslation();

	if (!data) {
		return null
	}

	return (
		<>
			{data.map((l) => (
				<div key={`nav-link-${l.id}`} onClick={closeMenu}>
					<HashLink
						to={l.attributes.href}
						className="hover:opacity-90 transition-colors"
						style={{ color: l.attributes.color }}
					>
						{t(`navLinks.${l.id}`)}
					</HashLink>
				</div>
			))}
		</>
	);
}
