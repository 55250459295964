import ReactMarkdown from "react-markdown";

export function Markdown({
	children,
	className,
}: {
	children: string;
	className?: string;
}) {
	return (
		<ReactMarkdown
			className={`text-sm [&_h3]:text-lg sm:[&_h3]:text-xl md:[&_h3]:text-2xl sm:[&_h4]:text-base md:[&_h4]:text-lg [&_a]:text-sky-800 [&_li]:list-disc [&_li]:list-inside [&>*]:mt-2 first:[&>*]:mt-0 ${className}`}
		>
			{children}
		</ReactMarkdown>
	);
}
